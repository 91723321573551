import React, { useEffect, useState } from 'react';
import { Parallax } from 'react-scroll-parallax';
import styled from 'styled-components';
import { Fade, H2, Section } from '../../atoms';
import { Container, Logo } from '../../molecules';

export default function InitialBanner(props) {

    const {
        mark,
        leftText,
        rightText,
        title
    } = props;

    const [ready, setReady] = useState(false);

    useEffect(() => {
        setReady(true);
    }, []);

    return (
        <Section>
            <Container>
                {mark && <Fade ready={ready} duration="4s"><Logo title={mark.title} subtitle={mark.subtitle} /></Fade>}
                <Fade ready={ready} duration="8s"><RightVerticalText>{rightText}</RightVerticalText></Fade>
                <Fade ready={ready} duration="6s"><LeftVerticalText>{leftText}</LeftVerticalText></Fade>
                <ParallaxContainer>
                    <div>
                        <Parallax className="custom-class" x={[10, -30]}>
                            <Fade duration="4s" transform="translateX(-25px)" ready={ready} delay="1s"><H2 textAlign="right">{title.line1}</H2></Fade>
                        </Parallax>
                        <Parallax className="custom-class" x={[-45, 20]}>
                            <Fade duration="4s" transform="translateX(25px)" ready={ready} delay="1.4s"><H2 textAlign="right">{title.line2}</H2></Fade>
                        </Parallax>
                        <Parallax className="custom-class" x={[0, -10]}>
                            <Fade duration="4s" transform="translateX(-25px)" ready={ready} delay="1.2s"><H2 textAlign="right">© {title.line3}</H2></Fade>
                        </Parallax>
                    </div>
                </ParallaxContainer>
            </Container>
        </Section>
    )
}

const ParallaxContainer = styled.div`
    height: 100vh;
    display: flex;
    align-items: flex-end;
    width: 100%;
    white-space: nowrap;

    > div {
        width: 100%;
        transform: translateY(11%);
    }

    @media screen and (min-width: 1920px) {
        align-items: center;
    }
    @media screen and (max-width: 991px) {
        align-items: center;
        > div {
            transform: none;
        }
    }
    @media screen and (max-width: 640px) {
        height: 640px;
    }
`;
const RightVerticalText = styled.div`
    font-family: BrownStd;
    color: #1B1919;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 1em;
    /** ===== */
    position: absolute;
    right: 30px;
    top: 5%;
    transform: rotate(90deg) translateX(100%);
    transform-origin: 100% 0;

    @media screen and (max-width: 767px) {
        right: 20px;
    }
    @media screen and (max-width: 520px) {
        right: 15px;
        top: 7.5%;
        font-size: 9px;
    }
`;
const LeftVerticalText = styled.div`
    font-family: BrownStd;
    color: #1B1919;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 1em;
    /** ===== */
    position: absolute;
    left: 30px;
    bottom: 5%;
    transform: rotate(-90deg);
    transform-origin: 0;

    @media screen and (max-width: 767px) {
        left: 20px;
    }
    @media screen and (max-width: 520px) {
        left: 15px;
        font-size: 9px;
        bottom: 0;
    }
`;