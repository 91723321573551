import React from 'react';
import styled from 'styled-components';
import { Section } from '../../atoms';
import { Container } from '../../molecules';

export default function StoryOfMyLife(props) {

    const {
        text,
        image
    } = props;

    return (
        <Section>
            <Wrapper>
                <Container>
                    <Content>
                        <div>
                            <Text dangerouslySetInnerHTML={{ __html: text }} />
                        </div>
                        <div>
                            <Gif src={image} />
                        </div>
                    </Content>
                </Container>
            </Wrapper>
        </Section>
    )
}
const Wrapper = styled.div`
    margin-bottom: 200px;
`;
const Gif = styled.img`
    height: 615px;
    
    @media screen and (max-width: 991px) {
        width: 100%;
        height: auto;
    }
`;
const Content = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    > div {
        flex: 1;
    }

    @media screen and (max-width: 991px) {
        display: block;
    }
`;
const Text = styled.div`
    font-family: BrownStd;
    font-weight: 700;
    font-size: 160px;
    line-height: 160px;
    letter-spacing: -2px;
    color: #000000;
    position: absolute;
    transform: translateY(-50%);

    @media screen and (max-width: 991px) {
        position: relative;
        transform: translateY(50%);
        font-size: calc(50px + (120 - 50) * ((100vw - 320px) / (991 - 320)));
        line-height: calc(70px + (120 - 70) * ((100vw - 320px) / (991 - 320)));

        span:last-child {
            color: #FFFFFF;
        }
    }
`;