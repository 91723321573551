import React from 'react';
import ReactCursorPosition from 'react-cursor-position';
import { Parallax } from 'react-scroll-parallax';
import styled from 'styled-components';
import { H3, Section } from '../../atoms';
import { Container } from '../../molecules';
import Lines from './item';
import LinesMobile from './itemMobile';


export default function Works(props) {
    const {
        bigTitle,
        intro,
        items
    } = props;
    
    return (
        <Section>
            <Overflow>
                <Parallax x={[20, -90]}>
                    <H3 pseudo={bigTitle}>{bigTitle}</H3>
                </Parallax>
            </Overflow>
            {
                intro && 
                <Header>
                    <LineTitle>
                        <Container>
                            <Left><Title>{intro.title}</Title></Left>
                            <Right><Subtitle>{intro.subtitle}</Subtitle></Right>
                        </Container>
                    </LineTitle>
                </Header>
            }
            <Desktop>
                <ReactCursorPosition>
                    <Lines items={items} />
                </ReactCursorPosition>
            </Desktop>
            <Mobile>
                <LinesMobile items={items} />
            </Mobile>
        </Section>
    )
}


const Desktop = styled.div`
    @media screen and (max-width: 992px) {
        display: none;
    }
`;
const Mobile = styled.div`
    @media screen and (min-width: 993px) {
        display: none;
    }
`;
const Overflow = styled.div`
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 112px;
`;

const Left = styled.div`
    width: 16%;
    margin-right: 4%;
    @media screen and (max-width: 767px) {
        width: 100%;
        margin: 0 0 20px;
    }
`;
const Right = styled.div`
    @media screen and (max-width: 767px) {
        width: 100%;
        margin: 0;
    }
`;
const Title = styled.div`
    color: #000000;	
    font-family: BrownStd;	
    font-size: 24px;	
    font-weight: 700;	
    line-height: 1em;
    text-transform: uppercase;

    @media screen and (max-width: 992px) {
        margin-bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (992 - 320)));
        font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (992 - 320)));
    }
`;
const Subtitle = styled.div`
    color: #1B1919;
    font-family: BrownStd;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;

    @media screen and (max-width: 992px) {
        font-size: calc(11px + (16 - 11) * ((100vw - 320px) / (992 - 320)));
    }
`;
const LineTitle = styled.div`
    padding: 80px 0;
    border-top: 1px solid rgba(0,0,0,.1);

    > div {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    @media screen and (max-width: 767px) {
        padding: 40px 0;
        > div {
            display: block;
        }
    }
    @media screen and (max-width: 520px) {
        padding: 20px 0;
    }
`;
const Header = styled.div`
    ${LineTitle} {
        padding: 0 0 60px;
        border: none;
        > div {
            align-items: flex-end;
        }

        @media screen and (max-width: 992px) {
            padding-bottom: calc(20px + (60 - 20) * ((100vw - 320px) / (992 - 320)));

            > div {
                display: block;
                > div {
                    width: 100%;
                }
            }
        }
    }
`;