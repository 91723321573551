import React, { useState } from 'react';
import ReactCursorPosition from 'react-cursor-position';
import { CursorOuter } from '../../components/molecules';
import About from '../../components/sections/about';
import Awards from '../../components/sections/awards';
import Contacts from '../../components/sections/contacts';
import Footer from '../../components/sections/footer';
import InitialBanner from '../../components/sections/initialBanner';
import InitialLoading from '../../components/sections/initialLoading';
import ProjectsSlider from '../../components/sections/projectsSlider';
import StoryOfMyLife from '../../components/sections/storyOfMyLife';
import Works from '../../components/sections/works';
import content from '../../content.json';

export default function Homepage(props) {

    const [animationComplete, setAnimationComplete] = useState(false);

    return (
        <div>
            <InitialLoading onCompleteAnimation={setAnimationComplete} {...content.loading} />
            {animationComplete &&
                <main>
                    <ReactCursorPosition>
                        <CursorOuter> 
                            <InitialBanner {...content.banner} />
                            <About {...content.about} />
                            <StoryOfMyLife {...content.story} />
                            <ProjectsSlider {...content.experience} />
                            <Works {...content.works} /> 
                            <Awards {...content.awards} />
                            <Contacts {...content.contacts} />
                            <Footer {...content.footer} />
                        </CursorOuter>
                    </ReactCursorPosition>
                </main>
            }
        </div>
    )
}


