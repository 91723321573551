import React from 'react';
import styled, { css } from 'styled-components';

export default function Logo(props) {
    const {
        title,
        subtitle,
        light
    } = props;

    return (
        <Element>
            <Title light={light}>{title}</Title>
            <Title light={light} subtitle>{subtitle}</Title>
        </Element>
    )
}
const Element = styled.div`
    margin-top: 20px;
    position: absolute;
`;
const Title = styled.div`
    font-family: Noe;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.36px;
    line-height: 24px;

    ${({ light }) => light ? css`
        color: ${({ subtitle }) => subtitle ? `rgba(255, 255, 255, .3)` : `#F9F6F4`}
    ` : css`
        color: ${({ subtitle }) => subtitle ? `rgba(27, 25, 25, .3)` : `rgba(27, 25, 25, 1)`}
    `}
`;

