import React, { useState } from 'react'
import styled from 'styled-components';
import Typist from 'react-typist';
import VisibilitySensor from 'react-visibility-sensor';
import { Section } from '../../atoms';
import { Container } from '../../molecules';

export default function Contacts(props) {

    const {
        bigText,
        social,
        text
    } = props;

    const [ready, setReady] = useState(false);

    const onChange = (visible) => {
        if (visible) {
            setReady(true);
        }
    }

    return (
        <Section>
            <VisibilitySensor onChange={onChange} partialVisibility="bottom" offset={{ top: 30 }}>
                <BigText>
                    {ready ? <Typist cursor={{ show: false }}>{bigText}</Typist> : <span>&nbsp;</span>}
                </BigText>
            </VisibilitySensor>
            <Container>
                <Text dangerouslySetInnerHTML={{ __html: text }} />
                <SocialNetworkList>
                    {social.map((item, key) => {
                        return <Item key={String(item.title)}><a href={item.link} target="_blank" rel="noopener noreferrer">{item.title}</a></Item>
                    })}
                </SocialNetworkList>
            </Container>
        </Section>
    )
}

const BigText = styled.div`
    margin-top: 280px;
    margin-bottom: 120px;
    font-family: BlackDiamond;
    font-weight: 400;
    color: #000000;
    font-size: 320px;
    line-height: 1em;
    text-align: center;
    min-height: 10px;

    @media screen and (max-width: 1440px) {
        font-size: calc(120px + (320 - 120) * ((100vw - 320px) / (1440 - 320)));
    }
    @media screen and (max-width: 992px) {
        margin-top: calc(140px + (280 - 140) * ((100vw - 320px) / (992 - 320)));
        margin-bottom: calc(88px + (120 - 88) * ((100vw - 320px) / (992 - 320)));
    }
`;
const Text = styled.div`
    color: #000000;
    font-size: 48px;
    max-width: 860px;
    font-weight: 400;
    line-height: 64px;
    letter-spacing: -1px;
    font-family: BrownStd;
    u, strong {
        font-weight: 700;
    }

    a {
        color: #000000;
        transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        cursor: none;
        :hover {
            cursor: none;
            color: rgba(27,25,25,.3);
        }
    }

    @media screen and (max-width: 1280px) {
        font-size: calc(24px + (48 - 24) * ((100vw - 320px) / (1280 - 320)));
        line-height: 1.4;
    }
`;
const SocialNetworkList = styled.ul`
    margin: 80px 0 120px;
    padding: 0;

    @media screen and (max-width: 1280px) {
        margin-top: calc(40px + (80 - 40) * ((100vw - 320px) / (1280 - 320)));
        margin-bottom: calc(60px + (120 - 60) * ((100vw - 320px) / (1280 - 320)));
    }
`;
const Item = styled.li`
    margin-right: 90px;
    display: inline-block;
    
    a {
        cursor: none;
        color: #000000;
        font-size: 24px;
        line-height: 32px;
        font-family: BrownStd;
        text-decoration: none;
        transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        :hover {
            cursor: none;
            color: rgba(27,25,25,.3);
        }

        @media screen and (max-width: 576px) {
            font-size: 22px;
        }
    }
    :last-child {
        margin: 0;
    }

    @media screen and (max-width: 1280px) {
        font-size: calc(12px + (24 - 12) * ((100vw - 768px) / (1280 - 768)));
        line-height: 1.5;
        margin-right: calc(25px + (90 - 25) * ((100vw - 768px) / (1280 - 768)));
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        font-size: 20px;
        line-height: 2.4;
    }
`;