import Component from '@reactions/component';
import React, { useState } from 'react';
import posed from 'react-pose';
import { Waypoint } from 'react-waypoint';
import styled, { css } from 'styled-components';
import { Container } from '../../molecules';
import ReactPlayer from 'react-player'


export default function LinesMobile(props) {
    const {
        items
    } = props;

    const [lineActive, setLineActive] = useState(null);

    const Thing = posed.div({
        visible: {
            opacity: 1
        },
        hidden: {
            opacity: 0
        },
        initialPose: 'hidden'
    });

    return (
        <Element>
            {items.map((item, index) => {
                return (
                    <Line key={String(index)} active={lineActive === index}>
                        <Component initialState={{ isOpen: false }}>
                            {({ setState, state }) => (
                                <Waypoint
                                    topOffset="40%"
                                    bottomOffset="45%"
                                    scrollableAncestor={window}
                                    onEnter={() => { setLineActive(index); setState({ isOpen: true }) }}
                                    onLeave={() => { setState({ isOpen: false }) }}
                                >
                                    <Thing
                                        pose={state.isOpen ? 'visible' : 'hidden'}
                                        style={{
                                            backgroundImage: `url(${!item.isVideo ? item.image : null})`,
                                            position: 'absolute',
                                            left: 0,
                                            right: 0,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            backgroundPosition: '40% 50%',
                                            zIndex: 1,
                                            top: 0,
                                            bottom: 0,
                                            margin: 0
                                        }}
                                    >
                                        {
                                            item.isVideo &&
                                            <ReactPlayer
                                                loop={true}
                                                muted={true}
                                                playing={true}
                                                width={"100%"}
                                                height={"100%"}
                                                playsinline={true}
                                                className='react-player'
                                                url={item.image}
                                            />
                                        }
                                    </Thing>
                                </Waypoint>
                            )}
                        </Component>
                        <a href={item.link} target='_blank' rel='noopener noreferrer'>
                            <Container>
                                <Left>
                                    <YearType>{item.year}<br />{item.type}</YearType>
                                </Left>
                                <Right>
                                    <WorkTitle before={item.title}>{item.title}</WorkTitle>
                                    <Link>{item.link}</Link>
                                </Right>
                            </Container>
                        </a>
                    </Line>
                )
            })}
        </Element>
    )
}

const Element = styled.div`
    position: relative;
    border-bottom: 1px solid rgba(0,0,0,.1);
`;

const Left = styled.div`
    width: 100%;
    margin: 0 0 20px;
`;
const Link = styled.div`
    color: #FFF;
    font-family: BrownStd;
    font-size: 12px;
    line-height: 1;
    opacity: 0;
    margin-top: 20px;
    transition: all 600ms ease;
`;
const YearType = styled.div`
    color: #1B1919;
    font-family: BrownStd;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 16px;
    transition: all 600ms ease;
`;
const WorkTitle = styled.div`
    font-family: BCStroke;
    font-weight: 500;
    font-size: 88px;
    line-height: 1em;
    z-index: 2;
    position: relative;

    &:after {
        content: '${({ before }) => before ? `${before}` : ``}';
        position: absolute;
        top: 0;
        left: 0;
        font-family: Noe;
        font-weight: 500;
        font-size: 88px;
        color: #1B1919; 
        line-height: 1em;
        transition: all 600ms ease;
    }

    @media screen and (max-width: 1280px) {
        font-size: calc(60px + (88 - 60) * ((100vw - 768px) / (1280 - 768)));
        &:after {
            font-size: calc(60px + (88 - 60) * ((100vw - 768px) / (1280 - 768)));
        }
    }
`;
const Line = styled.div`
    padding: 80px 0;
    position: relative;
    border-top: 1px solid rgba(0,0,0,.1);

    > div:first-child {
        transition: all 600ms ease;
    }

    > a {
        display: block;
        text-decoration: none;
        position: relative;
        z-index:2; 
        :hover {
            text-decoration: none;
        }
        :focus {
            outline: none;
        }
    }

    .react-player {
        video {
            object-fit: cover;
        }
    }

    ${({ active }) => active && css`
        ${YearType} {
            color: #FFF;
        }
        ${WorkTitle} {
            &:after {
                color: #F9F6F4;;
            }
        }
        ${Link} {
            opacity: 1;
        }
    `}
`;
const Right = styled.div`
    width: 100%;
    margin: 0;
`;
