import React from 'react'
import styled from 'styled-components'
import { Section } from '../../atoms';
import { Container } from '../../molecules';

export default function Footer(props) {

  const {
    leftText,
    rightText
  } = props;

  return (
    <Section>
      <Container>
        <Wrapper>
          <Copy dangerouslySetInnerHTML={{ __html: leftText }} />
          <Copy dangerouslySetInnerHTML={{ __html: rightText }} />
        </Wrapper>
      </Container>
    </Section>
  )
}

const Wrapper = styled.div`
  display: flex;
  padding-bottom: 120px;
  justify-content: space-between;
  @media screen and (max-width: 1280px) {
      padding-bottom: calc(40px + (120 - 40) * ((100vw - 320px) / (1280 - 320)));
  }
`;
const Copy = styled.div`
  color: #000000;
  font-size: 12px;
  line-height: 16px;
  font-family: BrownStd;
  font-weight: 400;
  a {
    color: #000000;
    transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: none;
    :hover {
      cursor: none;
      color: rgba(27,25,25,.3);
    }
  }
`;