import React from 'react'
import styled from 'styled-components';

export default function Container(props) {
    const {
        children
    } = props;
    return (
        <Element>
            {children}
        </Element>
    )
}


const Element = styled.div`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;

    @media screen and (min-width: 576px) {
        max-width: 540px;
    }
    @media screen and (min-width: 768px) {
        max-width: 720px;
    }
    @media screen and (min-width: 992px) {
        max-width: 960px;
    }
    @media screen and (min-width: 1200px) {
        max-width: 1140px;
    }
`;
