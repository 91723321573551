import styled from 'styled-components';

const H2 = styled.div`
    font-size: 240px;
    font-family: Noe;
    font-weight: 500;
    line-height: 200px;
    text-align: ${({ textAlign }) => textAlign ? textAlign : `left`};

    @media screen and (max-width: 1280px) {
        font-size: calc(80px + (240 - 80) * ((100vw - 320px) / (1280 - 320)));
        line-height: 0.9;
    }
`;

export default H2;
