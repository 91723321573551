import React from 'react';
import styled, { css } from 'styled-components';

export default function Section(props) {
    const {
        children,
        overflow
    } = props;
    return (
        <Element overflow={overflow}>
            {children}
        </Element>
    )
}

const Element = styled.div`
    position: relative;

    ${({ overflow }) => overflow && css`
        overflow: hidden;
    `}
`;
