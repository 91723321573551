import React from 'react'
import styled, { css } from 'styled-components';

export default function H3(props) {
    const {
        children,
        pseudo
    } = props;

    return (
        <Element pseudo={pseudo}>{children}</Element>
    )
}

const Element = styled.div`
    font-size: 280px;
    font-family: BrownStd;
    font-weight: 700;
    line-height: 350px;
    color: ${({ color }) => color ? color : `#FFFFFF`};
    vertical-align: middle;

    ${({ pseudo }) => pseudo && css`
        &::before {
            content: '${pseudo}';
            vertical-align: baseline;
        }
        &::after {
            content: '${pseudo}';
            vertical-align: baseline;
        }
    `}

    @media screen and (max-width: 1280px) {
        font-size: calc(60px + (280 - 60) * ((100vw - 320px) / (1280 - 320)));
        line-height: 1.3;
    }
`;