import React, { useState } from 'react';
import CountUp from 'react-countup';
import Typist from 'react-typist';
import VisibilitySensor from 'react-visibility-sensor';
import styled, { css } from 'styled-components';

export default function Number(props) {
    const {
        number,
        text,
        animation
    } = props;

    const [visible, setVisible] = useState(false);

    const onVisibilityChange = (isVisible) => {
        if (isVisible) {
            setVisible(true);
        }
    }

    return (
        <Element typing={animation}>
            <VisibilitySensor onChange={onVisibilityChange}>
                <React.Fragment>
                    {
                        (animation) ?
                        <Num>
                            {visible && 
                                <Typist cursor={{ show: false }}>{number} </Typist>
                            }
                        </Num>
                        :
                        <Num>
                            <CountUp
                                start={0}
                                end={visible ? number : 0} 
                                duration={3} 
                            />
                        </Num>
                    }
                    <Text dangerouslySetInnerHTML={{ __html: text }} />
                </React.Fragment>
            </VisibilitySensor>
        </Element>
    )
}
const Element = styled.div`
    margin-right: 90px;
    @media screen and (max-width: 1200px) {
        margin-bottom: 20px;
    }
    @media screen and (max-width: 520px) {
        margin-right: calc(80px + (25 - 80) * ((100vw - 375px) / (520 - 375)));   
    }
    @media screen and (max-width: 375px) {
        width: 100%;
        text-align: center;
        margin-right: 0; 
        margin-bottom: 40px;
    }

    ${({ typing }) => typing && css`
        @media screen and (max-width: 400px) {
            margin-right: 0;;   
        }
    `}
`;
const Num = styled.div`
    color: #1B1919;
    font-family: Noe;
    font-size: 120px;
    font-weight: 500;
    line-height: 1;
    min-height: 10px;
    white-space: nowrap;

    @media screen and (max-width: 520px) {
        font-size: calc(120px + (40 - 120px) * ((100vw - 320px) / (520 - 320)));
    }
`;
const Text = styled.div`
    color: #1B1919;
    font-family: BrownStd;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    white-space: nowrap;
`;