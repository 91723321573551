import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container } from '../../molecules';
import ReactPlayer from 'react-player'

export default function Lines(props) {
    const {
        items,
        isPositionOutside,
        position
    } = props;

    const [lineActive, setLineActive] = useState(null);
    const [transition, setTransition] = useState(false);

    useEffect(() => {
        setTransition(true);
        setTimeout(() => {
            setTransition(false);
        }, 600);
    }, [lineActive]);

    return (
        <Element>
            <Shower style={{ 'opacity': `${isPositionOutside ? 0 : 1}`, 'transform': `matrix(1, 0, 0, 1, ${position.x}, ${position.y})` }}>
                <div className={transition ? `change` : ``}>
                    {
                        lineActive != null &&
                        <>
                            {items[lineActive].isVideo ?
                                <ReactPlayer
                                    playsinline={true}
                                    loop={true}
                                    muted={true}
                                    playing={true}
                                    className='react-player'
                                    url={items[lineActive].image}
                                />
                                :
                                <img src={(lineActive != null) ? items[lineActive].image : '#'} alt={(lineActive != null) ? items[lineActive].title : '#'} />
                            }
                        </>
                    }
                </div>
            </Shower>
            {items.map((item, index) => {
                return (
                    <a href={item.link} target='_blank' rel='noopener noreferrer' key={String(index)}>
                        <Line onMouseEnter={() => setLineActive(index)}>
                            <Container>
                                <Left>
                                    <YearType>{item.year}<br />{item.type}</YearType>
                                </Left>
                                <Right>
                                    <WorkTitle before={item.title}><span>{item.title}</span></WorkTitle>
                                </Right>
                            </Container>
                        </Line>
                    </a>
                )
            })}
        </Element>
    )
}


const Shower = styled.div`
    z-index: 2;
    position: absolute;
    pointer-events: none;
    will-change: transform;
    transition: all 0 ease;

    > div {
        width: 710px;
        height: 400px;
        display: block;
        overflow: hidden;
        position: relative;
        transform: translate(-50%, -50%);

        img, .react-player {
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 2;
        }

        .react-player {
            min-width: 600px;
        }
    }

    .change {
        animation-name: blink;
        animation-duration: 400ms;

        @keyframes blink {
            0% {
                height: 400px
            }
            50% {
                height: 0;
            }
            100% {
                height: 400px
            }
        }
    }
`;
const Left = styled.div`
    width: 16%;
    margin-right: 4%;
    @media screen and (max-width: 767px) {
        width: 100%;
        margin: 0 0 20px;
    }
`;
const YearType = styled.div`
    color: #1B1919;
    font-family: BrownStd;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 16px;

    @media screen and (max-width: 991px) {
        color: #FFF;
    }
`;
const Right = styled.div`
    @media screen and (max-width: 767px) {
        width: 100%;
        margin: 0;
    }
`;
const WorkTitle = styled.div`
    font-family: BCStroke;
    font-weight: 500;
    font-size: 86px;
    line-height: 1em;
    position: relative;
    color: #1B1919;

    span {
        z-index: 1;
    }

    &:before {
        content: '${({ before }) => before ? `${before}` : ``}';
        position: absolute;
        top: 0;
        left: 0;
        font-family: Noe;
        font-weight: 500;
        font-size: 86px;
        color: black;
        line-height: 1em;
        z-index: 20;
        opacity: 0;
        transition: all 400ms ease;
    }

    @media screen and (max-width: 1280px) {
        font-size: calc(60px + (86 - 60) * ((100vw - 768px) / (1280 - 768)));
        &:before {
            font-size: calc(60px + (86 - 60) * ((100vw - 768px) / (1280 - 768)));
        }
    }
`;

const Line = styled.div`
    position: relative;
    border-top: 1px solid rgba(0,0,0,.1);

    > div {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 80px 0 60px;
    }

    :hover {
        ${WorkTitle} {
            span {
                opacity: 0;
            }
            &:before {
                opacity: 1;
            }
        }
    }
`;
const Element = styled.div`
    cursor: none;
    position: relative;
    border-bottom: 1px solid rgba(0,0,0,.1);
    a {
        text-decoration: none;
    }
`;