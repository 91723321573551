import React from 'react'
import styled from 'styled-components';

export default function H6(props) {
    const {
        children
    } = props;

    return (
        <Element>{children}</Element>
    )
}

const Element = styled.div`
    font-size: 36px;
    font-family: BrownStd;
    font-weight: 700;
    line-height: 54px;
    color: ${({ color }) => color ? color : `#FFFFFF`};
    vertical-align: middle;

    @media screen and (max-width: 1280px) {
        font-size: calc(16px + (36 - 16) * ((100vw - 320px) / (1280 - 320)));
        line-height: 1.3;
    }
`;