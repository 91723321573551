import React, { useState } from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';
import { Section, Fade, H3 } from '../../atoms';
import { Container } from '../../molecules';

export default function About(props) {

    const [ready, setReady] = useState(false)
    const [readyYears, setReadyYears] = useState(false);

    const startDate = new Date('2004-01-01');
    const endDate = new Date();
    const diff = endDate.getFullYear() - startDate.getFullYear();

    const onChange = (isVisible) => {
        if (isVisible !== ready) {
            setReady(isVisible);
        }
    }

    const onChangeYear = (isVisible) => {
        if (isVisible !== readyYears) {
            setReadyYears(isVisible);
        }
    }

    const {
        bigText,
        labelText4,
        text1,
        text2,
        text3,
        text4
    } = props;

    return (
        <Section>
            <Wrapper>
                <VisibilitySensor onChange={onChange} partialVisibility={"bottom"}>
                    <Container>
                        <Fade ready={ready} delay="0s" duration="1s" transform="translateY(30px)">
                            <Text1 dangerouslySetInnerHTML={{ __html: text1 }} />
                        </Fade>
                    </Container>
                </VisibilitySensor>
                <Overflow>
                    <Parallax x={[-20, -80]}>
                        <H3 pseudo={bigText}>{bigText}</H3>
                    </Parallax>
                </Overflow>
                <Container>
                    <Text2 dangerouslySetInnerHTML={{ __html: text2 }} />
                    <VisibilitySensor onChange={onChangeYear} partialVisibility={"bottom"}>
                        <Fade ready={readyYears} delay="0s" duration="2s" transform="translateX(-100%)">
                            <Years><CountUp duration={2} end={readyYears ? diff : 0} /> years</Years>
                        </Fade>
                    </VisibilitySensor>
                    <Fade ready={readyYears} opacity={1} delay="1s" duration="2s" transform="translateY(40%)">
                        <Text3 dangerouslySetInnerHTML={{ __html: text3 }} />
                    </Fade>
                    <KnowMore>
                        <Label>{labelText4}</Label>
                        <Text4 dangerouslySetInnerHTML={{ __html: text4 }} />
                    </KnowMore>
                </Container>
            </Wrapper>
        </Section>
    )
}

const Wrapper = styled.div`
    margin-top: 320px;

    @media screen and (max-width: 1280px) {
        margin-top: calc(40px + (320 - 40) * ((100vw - 320px) / (1280 - 320)));
    }
`;
const Overflow = styled.div`
    white-space: nowrap;
    overflow: hidden;
`;
const Text1 = styled.div`
    font-family: BrownStd;
    font-weight: 700;
    font-size: 72px;
    line-height: 88px;
    color: #000000;
    margin-bottom: 96px;

    @media screen and (max-width: 1280px) {
        font-size: calc(26px + (72 - 26) * ((100vw - 320px) / (1280 - 320)));
        line-height: 1.2;
    }
`;
const Text2 = styled.div`
    font-family: BrownStd;
    font-weight: 700;
    font-size: 56px;
    line-height: 72px;
    color: #000000;
    margin-top: 96px;

    @media screen and (max-width: 1280px) {
        font-size: calc(22px + (56 - 22) * ((100vw - 320px) / (1280 - 320)));
        line-height: 1.2;
    }
`;
const Text3 = styled.div`
    font-family: BrownStd;
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    color: #000000;
    max-width: 666px;

    @media screen and (max-width: 1280px) {
        font-size: calc(18px + (40 - 18) * ((100vw - 320px) / (1280 - 320)));
        line-height: 1.3;
    }
`;
const Text4 = styled.div`
    font-family: BrownStd;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    color: #000000;
    max-width: 470px;

    @media screen and (max-width: 1280px) {
        font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (1280 - 320)));
        line-height: 1.4;
    }
`;
const Years = styled.div`
    font-family: Noe;
    font-weight: 500;
    font-size: 240px;
    line-height: 306px;
    text-align: center;
    margin: 40px 0;

    @media screen and (max-width: 1280px) {
        font-size: calc(80px + (240 - 80) * ((100vw - 320px) / (1280 - 320)));
        line-height: 1.3;
    }
`;
const KnowMore = styled.div`
    margin: 200px 0;
    padding-left: 196px;

    @media screen and (max-width: 1280px) {
        padding-left: calc(40px + (196 - 40) * ((100vw - 320px) / (1280 - 320)));
        margin: calc(40px + (200 - 40) * ((100vw - 320px) / (1280 - 320))) 0;
    }
`;
const Label = styled.div`
    font-family: BrownStd;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 2px;
    margin-bottom: 32px;
    color: #1B1919;
    text-transform: uppercase;

    @media screen and (max-width: 1280px) {
        font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (1280 - 320)));
        margin-bottom: calc(10px + (32 - 10) * ((100vw - 320px) / (1280 - 320)));
    }
`;