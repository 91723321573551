import React from 'react';
import styled from 'styled-components';
import { Section } from '../../atoms';
import { Container, Number } from '../../molecules';

export default function Awards(props) {

    const {
        title,
        items
    } =  props;

    return (
        <Section>
            <Wrapper>
                <Container>
                    <Label>{title}</Label>
                    {
                        items &&
                        <Numbers>
                            {items.map((item, key) => (
                                <Number key={key} number={item.number} text={item.text} animation={typeof item.number === "string"} />
                            ))}
                        </Numbers>
                    }
                </Container>
            </Wrapper>
        </Section>
    )
}
const Wrapper = styled.div`
    margin-top: 240px;

    @media screen and (max-width: 992px) {
        margin-top: calc(120px + (240 - 120) * ((100vw - 320px) / (992 - 320)));
    }
`;
const Label = styled.div`
    color: #1B1919;
    font-family: BrownStd;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 24px;
    margin-bottom: 48px;
    text-transform: uppercase;
`;
const Numbers = styled.div`
    display: flex;
    flex-flow: wrap;
`;