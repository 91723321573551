import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import styled, { css } from 'styled-components';
import { H2 } from '../../atoms';
import { Container, Logo } from '../../molecules';


export default function Loading(props) {

    const {
        onCompleteAnimation,
        mark,
        firstYear
    } = props;

    const [start, setStart] = useState(false);
    const [complete, setComplete] = useState(false);

    var max = new window.Date().getFullYear();

    useEffect(() => {
        setTimeout(() => {
            setStart(true)
        }, 1000)
    }, []);

    const onComplete = () => {
        setComplete(true);
        onCompleteAnimation(true);
    }

    return (
        <Banner close={complete}>
            {mark &&
                <LogoLoading>
                    <Container>
                        <Logo title={mark.title} subtitle={mark.subtitle} light />
                    </Container>
                </LogoLoading>
            }
            <Inside>
                <Container>
                    <Animation>
                        <Date>
                            <H2>
                            © <CountUp
                                    duration={5}
                                    start={parseInt(firstYear)}
                                    end={start ? max : parseInt(firstYear)}
                                    onEnd={() => onComplete()}
                                />
                            </H2>
                        </Date>
                    </Animation>
                </Container>
            </Inside>
        </Banner>
    )
}

const LogoLoading = styled.div`
`;
const Banner = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    background-color: black;
    transition: all 400ms ease-in;

    ${({ close }) => close && css`
        height: 0vh;
    `} 
`;
const Inside = styled.div`
    display: flex;
    min-height: 100vh;
    align-items: flex-end;

    @media screen and (min-width: 1920px) {
        align-items: center;
    }
    @media screen and (max-width: 991px) {
        align-items: center;
    }
`;
const Animation = styled.div`
    width: 100%;
    display: flex;
    position: relative;
`;
const Date = styled.div`
    color: #FFFFFF;
    > div {
        line-height: 1;
    }
`;