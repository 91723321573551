import React from 'react';
import styled, { css } from 'styled-components';

export default function Fade(props) {
    const {
        children,
        transform,
        delay,
        ready,
        opacity,
        duration
    } = props;


    return (
        <Element
            ready={ready}
            delay={delay}
            opacity={opacity}
            duration={duration}
            transform={transform}>
            {children}
        </Element>
    )
}

const Element = styled.div`
    opacity: ${({ opacity }) => opacity ? opacity : 0};
    transition: all ${({ duration }) => duration ? duration : `800ms`} cubic-bezier(0.275, 0.985, 0.42, 1.275) ${({ delay }) => delay ? delay : `800ms`};
    transform: ${({ transform }) => transform ? transform : `none`};

    ${({ ready }) => ready && css`
        opacity: 1;
        transform: none;
    `}
`;