import React from 'react';
import ReactGA from 'react-ga';
import { ParallaxProvider } from 'react-scroll-parallax';
import Homepage from './containers/Homepage';
import './static/styles/fonts.css';
import './static/styles/styles.css';

function App(props) {


  ReactGA.initialize("UA-132689598-1");
  ReactGA.pageview(window.location.pathname + window.location.search);


  return (
    <React.Fragment>
      <ParallaxProvider>
        <Homepage />
      </ParallaxProvider>
    </React.Fragment>
  );
}

export default App;
