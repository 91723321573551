import React from 'react'
import styled from 'styled-components';

export default function H4(props) {
    const {
        children
    } = props;

    return (
        <Element>{children}</Element>
    )
}

const Element = styled.div`
    font-size: 48px;
    font-family: Noe;
    font-weight: 700;
    line-height: 480px;
    color: ${({ color }) => color ? color : `#FFFFFF`};
    vertical-align: middle;

    @media screen and (max-width: 1280px) {
        font-size: calc(32px + (48 - 32) * ((100vw - 320px) / (1280 - 320)));
        line-height: 1.3;
    }
`;