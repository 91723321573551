import React from 'react';
import styled from 'styled-components';

export default function CursorOuter(props) {

    const {
        position,
        isPositionOutside,
        children
    } = props;

    return (
        <Element>
            {!isPositionOutside &&
                <React.Fragment>
                    <CursorA style={{ "opacity": `1`, "transform": `matrix(1, 0, 0, 1, ${position.x}, ${position.y})` }}/>
                    <CursorB style={{ "opacity": `1`, "transform": `matrix(1, 0, 0, 1, ${position.x}, ${position.y})` }}>
                        <div />
                    </CursorB>
                </React.Fragment>
            }
            {children}
        </Element>
    )
}

const CursorA = styled.div`
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    z-index: 9999;
    margin-top: -4px;
    margin-left: -4px;
    position: absolute;
    pointer-events: none;
    border-radius: 9999px;
    background-color: #000;
    will-change: transform;

    @media screen and (max-width: 992px) {
        display: none;
    }
`;
const CursorB = styled.div`
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
    z-index: 9999;
    margin-top: -18px;
    margin-left: -18px;
    position: absolute;
    pointer-events: none;
    will-change: transform;
    transition: all 400ms ease;

    div {
        top: 50%;
        left: 50%;
        width: 36px;
        height: 36px;
        opacity: .65;
        border-width: 1px;
        position: absolute;
        border-color: #000;
        border-style: solid;
        pointer-events: none;
        border-radius: 9999px;
        transform: translate3d(-50%,-50%,0);
    }

    @media screen and (max-width: 992px) {
        display: none;
    }
`;
const Element = styled.div`
    cursor: none;
    position: relative;

    @media screen and (max-width: 992px) {
        cursor: auto;
    }
`;